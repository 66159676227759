export const getApiUrl = () => {
  const nodeEnv = process.env.NODE_ENV || "production";

  return {
    development: "https://esg-admin.cricketone.asia/api",
    production: "https://esg-admin.cricketone.asia/api",
  }[nodeEnv];

  // return {
  //   development: "http://localhost:1337/api",
  //   production: "https://esg-admin.cricketone.asia/api",
  // }[nodeEnv];
};

export const getDemeterApi = () => {
  const nodeEnv = process.env.NODE_ENV || "production";

  return {
    development: "https://stage-api.demeter.vn",
    production: "https://stage-api.demeter.vn",
  }[nodeEnv];

  // return {
  //   development: "http://localhost:3004",
  //   production: "https://stage-api.demeter.vn",
  // }[nodeEnv];
};

export const getAccessToken = () => {
  const nodeEnv = process.env.NODE_ENV || "production";

  return {
    development:
      "8754e32ce3aeb6576a1780686793c988f617ff015a370d9fe6552625b06cd78b2146e1ba6b869e212d8b84e5b5a0e841aaa7bc33995a0d62912d151a935a2fe3a52a5983abc12e8bc334b7d6d4129977e50c29f7ce646ddbca9ffcefc69fe7031f099afa8ad54a9baad17bd61a1aac9948492d10cdc083f91d5ba27a91a453e5",
    production:
      "8754e32ce3aeb6576a1780686793c988f617ff015a370d9fe6552625b06cd78b2146e1ba6b869e212d8b84e5b5a0e841aaa7bc33995a0d62912d151a935a2fe3a52a5983abc12e8bc334b7d6d4129977e50c29f7ce646ddbca9ffcefc69fe7031f099afa8ad54a9baad17bd61a1aac9948492d10cdc083f91d5ba27a91a453e5",
  }[nodeEnv];

  // return {
  //   development:
  //     "f993f2804faf9bc950493f6c4b1448ba3f8fffb741ac128694b29fa2690f35c16d35adda8b461221e17be1e1c4fbf44dceec55a9feb35a2002ce192f1355a8931461fa7b3b7ecbe40ad41da7484a5b34919a24086a2c801954d2764ab16abac2149f5fa008cad483ad1d95dddd6b25f292a625521f2b8a033ea9d1261662470c",
  //   production:
  //     "8754e32ce3aeb6576a1780686793c988f617ff015a370d9fe6552625b06cd78b2146e1ba6b869e212d8b84e5b5a0e841aaa7bc33995a0d62912d151a935a2fe3a52a5983abc12e8bc334b7d6d4129977e50c29f7ce646ddbca9ffcefc69fe7031f099afa8ad54a9baad17bd61a1aac9948492d10cdc083f91d5ba27a91a453e5",
  // }[nodeEnv];
};

export const WATER_CHART_RANGE = {
  last6Hours: "Last 6 hours",
  lastWeek: "Last week",
  last6Months: "Last 6 months",
};
